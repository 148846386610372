import React from 'react';
import styled, { css } from 'styled-components';

import IconArrowLeft from 'images/arrow-left-pagination.svg';
import IconArrowRight from 'images/arrow-right-pagination.svg';

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageBtn = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: #5fd2da;
  width: 32px;
  height: 32px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #eafeff;
      border: 1px solid #5fd2da;
      border-radius: 8px;
    `}
`;

const PreviousBtn = styled.img`
  margin-right: 18px;
  cursor: pointer;
`;

const NextBtn = styled.img`
  margin-left: 18px;
  cursor: pointer;
`;

const DISPLAY_COUNT = 9; // 目前只有處理單數顯示邏輯

const Pagination = (props) => {
  const { className, current, pageSize = 10, total = 0, onPageChange } = props;

  const totalPages = Math.ceil(total / pageSize);
  const halfList = Array(Math.floor(DISPLAY_COUNT / 2)).fill(null);
  let paginationList = [current];
  if (totalPages < DISPLAY_COUNT) {
    paginationList = Array(totalPages)
      .fill(null)
      .map((_, idx) => idx + 1);
  } else {
    if (halfList.map((_, idx) => idx + 1).includes(current)) {
      paginationList = Array(DISPLAY_COUNT)
        .fill(null)
        .map((_, idx) => {
          return idx + 1;
        });
    } else if ([totalPages, totalPages - 1].includes(current)) {
      paginationList = Array(DISPLAY_COUNT)
        .fill(null)
        .map((_, idx) => {
          return totalPages - (DISPLAY_COUNT - idx - 1);
        });
    } else {
      halfList.forEach((_, idx) => {
        const num = idx + 1;
        paginationList.unshift(current - num);
        paginationList.push(current + num);
      });
      paginationList = paginationList.filter(
        (page) => !(page < 1 || page > totalPages)
      );
    }
  }

  const goToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClickPrevious = () => {
    onPageChange(current - 1);
  };

  const handleClickNext = () => {
    onPageChange(current + 1);
  };

  const handleClickPageBtn = (page) => {
    onPageChange(page);
  };

  return (
    <Container className={className}>
      {current !== 1 && (
        <PreviousBtn src={IconArrowLeft} onClick={handleClickPrevious} />
      )}
      {paginationList?.map((page) => {
        return (
          <PageBtn
            key={page}
            isActive={current === page}
            onClick={() => handleClickPageBtn(page)}
          >
            {page}
          </PageBtn>
        );
      })}
      {current !== totalPages && (
        <NextBtn src={IconArrowRight} onClick={handleClickNext} />
      )}
    </Container>
  );
};

export default Pagination;
