import React from 'react';
import styled from 'styled-components';

import { md } from 'utils/breakpoints';
import { Helmet } from 'react-helmet';
import { SEO_DOMAIN } from 'utils/constants/seo.js';

const SeoCategory = ({ serverData }) => {
  return (
    <>
      <h1>{`${serverData?.categoryName}`}</h1>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.result?.data?.rows?.map((product, idx) => {
            return (
              <div typeof="schema:Product" key={`ResultData-${idx}`}>
                <div
                  property="schema:name"
                  content={product?.productName}
                ></div>
                <div rel="schema:image" resource={product?.image_path}></div>
                <div
                  rel="schema:url"
                  resource={`${SEO_DOMAIN}/product?pid=${product?.productId}`}
                ></div>
                <div rel="schema:offers">
                  <div typeof="schema:Offer">
                    <div
                      property="schema:price"
                      content={
                        product?.promo_price !== product?.price
                          ? product?.promo_price
                          : product?.price
                      }
                    ></div>
                    <div
                      property="schema:availability"
                      content="https://schema.org/InStock"
                    ></div>
                    <div property="schema:priceCurrency" content="TWD"></div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div typeof="schema:ItemList">
        <div rel="schema:itemListElement">
          {serverData?.cateMenu?.data?.rows
            ?.map((cate) => cate?.sub)
            .map((subItem, idx) => {
              return (
                <div typeof="schema:ListItem" key={`cateMenu-${idx}`}>
                  <div property="schema:position" content={idx + 1}></div>
                  <div property="schema:name" content={subItem?.name}></div>
                  <div
                    rel="schema:url"
                    resource={`${SEO_DOMAIN}/category?cid=${subItem?.id}`}
                  ></div>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
};

export default SeoCategory;
