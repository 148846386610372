import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Link, navigate } from 'gatsby';
import store, { actions, getters } from 'redux/store';
import { useSelector } from 'react-redux';
import { isDesktop, isMobile } from 'react-device-detect';
import Skeleton from 'react-loading-skeleton';
import { FixedSizeList as List } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import {
  SEO_API_CATEGORIES_NOTOKEN,
  SEO_API_CATEGORY_PRODUCT_NOTOKEN,
} from 'utils/constants/seo.js';
import MetaCategory from 'components/Seo/Meta/MetaCategory';
import LdCategory from 'components/Seo/JsonLd/LdCategory';
import CcAuth from 'components/CcAuth';
import HeaderWrapper from 'components/UI/Header/HeaderWrapper';
import Header from 'components/UI/Header';
import Footer from 'components/UI/Footer';
import Pagination from 'components/UI/Pagination';
import Breadcrumb from 'components/UI/Breadcrumb';
import CardProduct from 'components/UI/Card/CardProduct';
import CategoryMenu from 'components/Homepage/CategoryMenu';
import SeoCategory from 'components/Seo/SeoCategory';
import Empty from 'components/UI/Empty';
import isbot from 'isbot';
import { md } from 'utils/breakpoints';
import { useAPI } from 'utils/api';
import { isAuthenticated } from 'utils/_auth.js';
import { getDispatchData } from 'utils/_dispatch.js';
import { setSelectItem } from 'utils/_gtag.js';
import {
  PATH_HOME,
  PATH_CATEGORY,
  PATH_PRODUCT,
} from 'utils/constants/paths.js';

const mixinSubCategoryList = css`
  display: flex;
  gap: 0 8px;
  flex-wrap: nowrap;
`;

const mixinSubCategoryItem = css`
  border-radius: 30px;
  height: 28px;
`;

const mixinMainCategoryList = css`
  display: flex;
  gap: 0 2px;
`;

const mixinMainCategoryItem = css`
  padding: 6px 4px;
  flex: 0 0 auto;
`;

const mixinCategoryList = css`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;

  @media (max-width: ${md}) {
    gap: 8px;
  }
`;

const mixinCardProduct = css`
  flex: 0 0 calc((100% - 3 * 12px) / 4);
  min-height: 291px;

  @media (max-width: ${md}) {
    flex: 0 0 calc((100% - 2 * 8px) / 3);
    width: 90px;
    min-height: 184px;
  }
`;

const Container = styled.div`
  width: 1080px;
  margin: ${(props) => `${props.headerHeight}px`} auto 0;

  @media (max-width: ${md}) {
    width: 100%;
    margin: 0 auto;
    padding-top: ${(props) => `${props.headerHeight + 80}px`};
  }
`;

const StyledHeader = styled(Header)`
  .switch-delivery-wrapper,
  .address-wrapper {
    @media (max-width: ${md}) {
      display: none;
    }
  }

  .title {
    top: 0;
    left: 50%;
    transform: translate(-50%, 50%);
  }
`;

const StyledFooter = styled(Footer)`
  @media (max-width: ${md}) {
    margin-top: 0;
  }
`;

const StyledCardProduct = styled(CardProduct)`
  ${mixinCardProduct}
`;

const StyledPagination = styled(Pagination)`
  margin-top: 20px;
`;

const Content = styled.div`
  display: flex;
  gap: 0 17px;

  @media (max-width: ${md}) {
    flex-direction: column;
  }
`;

const CategoryListWrapper = styled.div`
  flex: 1;

  .skeleton-category-list {
    ${mixinCategoryList}

    .skeleton-product {
      ${mixinCardProduct}
    }
  }
`;

const CategoryListTitle = styled.div`
  font-weight: 700;
  font-size: 32px;
  color: #3b3516;

  @media (max-width: ${md}) {
    font-size: 18px;
    margin-bottom: 0;
  }
`;

const CategoryList = styled.div`
  ${mixinCategoryList}
`;

const Section = styled.div`
  @media (max-width: ${md}) {
    background-color: #fff;
    padding: 0 15px 9px;
  }
`;

const TitleBar = styled.div`
  margin-bottom: 12px;

  @media (max-width: ${md}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 4px;
  }

  .skeleton-category-list-title {
    width: 250px;
    height: 25px;

    @media (max-width: ${md}) {
      width: 100px;
      height: 15px;
    }
  }
`;

const MobileScrollGroup = styled.div`
  display: none;

  @media (max-width: ${md}) {
    display: block;
    position: fixed;
    top: ${(props) => props.headerHeight}px;
    left: 0;
    right: 0;
    z-index: 1000;
    background-color: #fff;
  }

  .main-scroll-wrapper {
    width: 100vw;
    overflow: scroll;
    margin-bottom: 8px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .sub-scroll-wrapper {
    width: 100vw;
    overflow: scroll;
    margin-bottom: 11px;
    padding: 0 15px;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .skeleton-main-category-list {
    ${mixinMainCategoryList}
    padding: 5px;

    .skeleton-main-category-items {
      ${mixinMainCategoryItem}
    }
  }

  .skeleton-sub-category-list {
    ${mixinSubCategoryList}
    padding: 10px 15px;

    .skeleton-sub-category-item {
      ${mixinSubCategoryItem}
    }
  }
`;

const MainCategoryList = styled.div`
  ${mixinMainCategoryList}
`;

const MainCategoryItem = styled.div`
  ${mixinMainCategoryItem}
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;

  ${(props) =>
    props.isActive &&
    css`
      border-bottom: 1px solid #5fd2da;
      font-weight: 500;
    `};
`;

const SubCategoryList = styled.div`
  ${mixinSubCategoryList}
`;

const SubCategoryItem = styled.div`
  ${mixinSubCategoryItem}
  flex: 0 0 auto;
  padding: 4px 8px;
  background-color: #ffffff;
  border: 1px solid #e3e3e3;
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;
  display: flex;
  justify-content: center;
  align-items: center;

  ${(props) =>
    props.isActive &&
    css`
      background-color: #5fd2da;
      border: 1px solid #5fd2da;
      color: #ffffff;
      font-weight: 700;
    `};
`;

let total = 0;
let currentPage = 1;
let totalPage = 0;
const perPage = 24;
let arrProducts = [];

let itemStatusMap = {};

const isGoogleBot = (res) => {
  return /Googlebot/.test(res);
};

let qtyStates = false;
const CategoryProduct = ({ location, serverData }) => {
  console.log('serverData', serverData);
  const api = useAPI();
  const dispatchData = getDispatchData();
  const isLogin = isAuthenticated();
  const storeId = dispatchData?.storeId ?? '';
  const searchParams = new URLSearchParams(location.search);
  const categoryId = searchParams.get('cid');
  if (isDesktop)
    currentPage = searchParams.get('page')
      ? Number(searchParams.get('page'))
      : 1;

  const userInfo = useSelector((state) => getters.getUserInfo(state));
  const categoryList = useSelector((state) => getters.getCategoryList(state));
  const cartProductList = useSelector((state) =>
    getters.getCartProductCountList(state)
  );

  const [headerHeight, setHeaderHeight] = useState(0);
  const [productListInfo, setProductListInfo] = useState({});
  const [productList, setProductList] = useState();
  const [domLoaded, setDomLoaded] = useState(false);
  const [reFreshCategoryMenu, setReFreshCategoryMenu] = useState(false);
  const [ccAuthIsReady, setCcAuthIsReady] = useState(false);

  const subCategoryList = categoryList?.find((item) => {
    const sub = item.sub.find((i) => {
      return i.id === categoryId;
    });
    return sub;
  });

  const subCategoryName = subCategoryList?.sub.find((item) => {
    return item.id === categoryId;
  })?.name;

  const handleChangeCount = (count, productId) => {
    const updatedProduct = productList.map((product) => {
      if (productId === product.id) {
        return {
          ...product,
          qty: count,
        };
      }
      return product;
    });
    setProductList(updatedProduct);
  };

  const initializationQty = (callBack) => {
    const newProds = [];
    arrProducts.forEach((p) => {
      let result = cartProductList?.find((infoProd) => {
        return p.id === infoProd.productNo;
      });
      const asProd = Object.assign({}, p);
      if (result !== undefined) {
        asProd.qty = result.count;
      } else {
        asProd.qty = 0;
      }
      newProds.push(asProd);
    });
    setProductList(newProds);
    if (callBack) callBack();
  };

  const getProducts = (id) => {
    const params = {
      categoryId: categoryId,
      currentPage: currentPage,
      perPage: perPage,
      storeId: id ?? storeId,
      customerNo: userInfo.customerNo,
    };
    api
      .getCategoriesProducts(params)
      .then((res) => {
        console.log('getCategoriesProducts', res);
        if (!res) {
          navigate(PATH_HOME);
          return;
        }
        const rowArr = res.rows;
        if (isMobile) {
          setProductListInfo(res);
          if (currentPage === 1 || currentPage <= totalPage) {
            arrProducts = [...arrProducts, ...rowArr];
            currentPage = currentPage + 1;
            totalPage = Number.parseInt(res.total / perPage);
            if (res.total % perPage !== 0) {
              totalPage++;
            }
            if (res.total === res.rows.length) {
            }
            setProductList(arrProducts);
          }
        } else {
          arrProducts = [...rowArr];
          total = res.total;
          setProductList(arrProducts);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getCategoriesProductNoToken = () => {
    const params = {
      categoryId: categoryId,
      currentPage: currentPage,
      perPage: perPage,
      storeId: storeId,
      customerNo: userInfo.customerNo,
    };
    api
      .getCategoriesProductNoToken(params)
      .then((res) => {
        if (!res) {
          navigate(PATH_HOME);
          return;
        }
        const rowArr = res.rows;
        if (isMobile) {
          setProductListInfo(res);
          if (currentPage === 1 || currentPage <= totalPage) {
            arrProducts = [...arrProducts, ...rowArr];
            currentPage = currentPage + 1;
            totalPage = Number.parseInt(res.total / perPage);
            if (res.total % perPage !== 0) {
              totalPage++;
            }

            setProductList(arrProducts);
          }
        } else {
          arrProducts = [...rowArr];
          total = res.total;
          setProductList(arrProducts);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const updateCartProductList = (item) => {
    const newArray = item.map((itemElement) => {
      // 根據需要進行轉換和重組
      return {
        // 新的元素結構和屬性名稱
        productNo: itemElement.productId,
        count: itemElement.qty,
      };
    });
    store.dispatch(actions.setCartProductCountList(newArray));
  };

  useEffect(() => {
    setDomLoaded(true);
    store.dispatch(actions.setCartProductCountList([]));
    qtyStates = false;
  }, []);

  useEffect(() => {
    if (!categoryId) {
      navigate(PATH_HOME);
      return;
    }
    setProductList(undefined);
    arrProducts = [];
    totalPage = 0;
    if (isMobile) currentPage = 1;

    if (isLogin && storeId !== '') {
      getProducts();
    } else if (!isLogin && storeId !== '') {
      getCategoriesProductNoToken();
    }
  }, [categoryId]);

  // （手機版）第一、二層分類狀態是 active 時，標籤會移動到最前方
  useEffect(() => {
    if (!isMobile) return;
    const mainScrollWrapperEle = document.querySelector('.main-scroll-wrapper');
    const mainCategoryEleList = document.querySelectorAll('.main-category');
    let currentMainCategoryEle = null;
    mainCategoryEleList.forEach((ele) => {
      if (ele.textContent === subCategoryList.name) {
        currentMainCategoryEle = ele;
      }
    });
    if (mainScrollWrapperEle && currentMainCategoryEle) {
      mainScrollWrapperEle.scrollLeft = currentMainCategoryEle.offsetLeft;
    }

    const subScrollWrapperEle = document.querySelector('.sub-scroll-wrapper');
    const subCategoryEleList = document.querySelectorAll('.sub-category');
    let currentSubCategoryEle = null;
    subCategoryEleList.forEach((ele) => {
      if (ele.textContent === subCategoryName) {
        currentSubCategoryEle = ele;
      }
    });
    if (subScrollWrapperEle && currentSubCategoryEle) {
      subScrollWrapperEle.scrollLeft = currentSubCategoryEle.offsetLeft - 15;
    }
  }, [ccAuthIsReady, subCategoryName]);

  useEffect(() => {
    if (!qtyStates && cartProductList.length > 0 && productList)
      initializationQty(() => {
        qtyStates = true;
      });
  }, [cartProductList]);

  useEffect(() => {
    if (productList) initializationQty();
  }, [arrProducts]);

  const Row = (props) => {
    const { index, style } = props;

    if (
      (index + 1) * 3 < productListInfo.total &&
      index + 1 === productList.length / 3
    ) {
      return <div style={style}>資料載入中...請稍候</div>;
    }

    return (
      <div style={style}>
        <div style={{ display: 'flex', gap: 8 }}>
          {productList.slice(index * 3, index * 3 + 3).map((p, idx) => {
            const itemData = {
              id: p?.id,
              storeId: storeId,
              img: p?.image,
              price: p?.promo_price,
              originalPrice: p?.price,
              name: p?.item_name,
              count: p?.qty,
              promotions: p?.productPromotions,
            };
            return (
              <StyledCardProduct
                key={idx}
                goToPage={`${PATH_PRODUCT}?pid=${itemData.id}`}
                item={itemData}
                productData={p}
                boardInfo={{
                  id: categoryId,
                  name: subCategoryName,
                }}
                onGo2Detail={(id) => {
                  // GTAG: 點選"主題商品類別"區商品
                  setSelectItem(
                    {
                      listId: categoryId,
                      listName: subCategoryName,
                    },
                    p
                  );
                }}
                onChangeCount={(count, productId) => {
                  handleChangeCount(count, productId);
                }}
                updateCartProductList={updateCartProductList}
              />
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <>
      <CcAuth onFinished={() => setCcAuthIsReady(true)} />
      {serverData?.status === 200 && (
        <>
          <MetaCategory serverData={serverData} />
          <LdCategory serverData={serverData} />
        </>
      )}

      {isbot(serverData?.useragent) ? (
        <SeoCategory serverData={serverData} />
      ) : domLoaded && ccAuthIsReady ? (
        <Container headerHeight={headerHeight}>
          <HeaderWrapper getHeight={(height) => setHeaderHeight(height)}>
            <StyledHeader
              title="分類商品"
              onRefresh={(storeId) => {
                store.dispatch(actions.setCartProductCountList([]));
                getProducts(storeId);
                setReFreshCategoryMenu(true);
              }}
            />
          </HeaderWrapper>
          <Section>
            <Breadcrumb
              items={[
                { title: <Link to="/">首頁</Link> },
                { title: <Link>{subCategoryList?.name}</Link> },
                { title: <Link>{subCategoryName}</Link> },
              ]}
            />
            <Content>
              <MobileScrollGroup headerHeight={headerHeight}>
                {categoryList !== undefined ? (
                  <MainCategoryList className="main-scroll-wrapper">
                    {categoryList?.map((item, idx) => {
                      return (
                        <MainCategoryItem
                          key={idx}
                          className="main-category"
                          onClick={() =>
                            navigate(`${PATH_CATEGORY}?cid=${item.sub[0].id}`)
                          }
                          isActive={item.name === subCategoryList?.name}
                        >
                          {item.name}
                        </MainCategoryItem>
                      );
                    })}
                  </MainCategoryList>
                ) : (
                  <Skeleton
                    containerClassName="skeleton-main-category-list"
                    count={5}
                  />
                )}
                {subCategoryList !== undefined ? (
                  <SubCategoryList className="sub-scroll-wrapper">
                    {subCategoryList?.sub.map((item, idx) => {
                      return (
                        <SubCategoryItem
                          key={idx}
                          className="sub-category"
                          onClick={() =>
                            navigate(`${PATH_CATEGORY}?cid=${item.id}`)
                          }
                          isActive={item.id === categoryId}
                        >
                          {item.name}
                        </SubCategoryItem>
                      );
                    })}
                  </SubCategoryList>
                ) : (
                  <Skeleton
                    containerClassName="skeleton-sub-category-list"
                    className="skeleton-sub-category-item"
                    count={4}
                  />
                )}
              </MobileScrollGroup>
              <CategoryMenu
                height={'715px'}
                subCategoryId={categoryId}
                onRefresh={reFreshCategoryMenu}
                onFinish={() => setReFreshCategoryMenu(false)}
              />
              <CategoryListWrapper>
                <TitleBar>
                  {subCategoryName ? (
                    <CategoryListTitle>{subCategoryName}</CategoryListTitle>
                  ) : (
                    <Skeleton className="skeleton-category-list-title" />
                  )}
                </TitleBar>
                {productList === undefined ? (
                  <Skeleton
                    containerClassName="skeleton-category-list"
                    className="skeleton-product"
                    count={24}
                    inline
                  />
                ) : productList.length === 0 ? (
                  <Empty description="尚無商品" />
                ) : isMobile ? (
                  <InfiniteLoader
                    isItemLoaded={(index) => !!itemStatusMap[index]}
                    itemCount={productList?.length}
                    loadMoreItems={(startIndex, stopIndex) => {
                      if (
                        stopIndex - 14 ===
                        Math.ceil(productList?.length / 3)
                      ) {
                        if (productListInfo.total >= productList?.length) {
                          isLogin
                            ? getProducts()
                            : getCategoriesProductNoToken();
                        }
                      }
                    }}
                  >
                    {({ onItemsRendered, ref }) => (
                      <List
                        height={window.innerHeight - headerHeight}
                        itemCount={Math.ceil(productList?.length / 3)}
                        width="100%"
                        ref={ref}
                        onItemsRendered={onItemsRendered}
                        itemSize={215}
                      >
                        {Row}
                      </List>
                    )}
                  </InfiniteLoader>
                ) : (
                  <>
                    <CategoryList>
                      {productList.map((product, idx) => {
                        const itemData = {
                          id: product.id,
                          storeId: storeId,
                          img: product.image,
                          price: product.promo_price,
                          originalPrice: product.price,
                          name: product.title,
                          count: product.qty,
                          promotions: product.productPromotions,
                        };
                        return (
                          <StyledCardProduct
                            key={idx}
                            goToPage={`${PATH_PRODUCT}?pid=${itemData.id}`}
                            item={itemData}
                            productData={product}
                            boardInfo={{
                              id: categoryId,
                              name: subCategoryName,
                            }}
                            onGo2Detail={(id) => {
                              // GTAG: 點選"主題商品類別"區商品
                              setSelectItem(
                                {
                                  listId: categoryId,
                                  listName: subCategoryName,
                                },
                                product
                              );
                            }}
                            onChangeCount={(count, productId) => {
                              handleChangeCount(count, productId);
                            }}
                            updateCartProductList={updateCartProductList}
                          />
                        );
                      })}
                    </CategoryList>
                    <StyledPagination
                      total={total}
                      current={currentPage}
                      pageSize={perPage}
                      onPageChange={(page) => {
                        setProductList(undefined);
                        currentPage = page;
                        isLogin ? getProducts() : getCategoriesProductNoToken();
                        navigate(
                          `${PATH_CATEGORY}?cid=${categoryId}&page=${page}`
                        );
                      }}
                    />
                  </>
                )}
              </CategoryListWrapper>
            </Content>
          </Section>
          <StyledFooter />
        </Container>
      ) : undefined}
    </>
  );
};

export default CategoryProduct;

export async function getServerData(context) {
  try {
    const headers = context.headers;
    const categoryId = context.query.cid;
    if (!categoryId) {
      throw new Error(`Response failed`);
    }
    const currentUrl = 'https://' + headers.get('host') + '?cid=' + categoryId;
    const host = headers.get('host');

    const [res1, res2] = await Promise.all([
      fetch(
        `${SEO_API_CATEGORY_PRODUCT_NOTOKEN}?categoryId=${categoryId}&catalogId&page=1&per_page=24&storeId=02H`
      ),
      fetch(`${SEO_API_CATEGORIES_NOTOKEN}?storeId=02H`),
    ]);
    if (!res1.ok || !res2.ok) {
      throw new Error(`Response failed`);
    }

    const result = await res1.json();
    const cateMenu = await res2.json();

    if (result.code != '200' || cateMenu.code != '200') {
      throw new Error(`Response failed`);
    }

    console.log('cateMenu', cateMenu);
    let categoryName = '';
    cateMenu?.data?.rows.forEach((p) => {
      p.sub.forEach((s) => {
        if (s.id === categoryId) {
          categoryName = s.name;
        }
      });
    });

    const mergedData = {
      status: 200,
      result,
      cateMenu,
      useragent: headers.get('user-agent'),
      host: host,
      currentUrl: currentUrl,
      categoryName: categoryName,
    };

    return {
      props: await mergedData,
    };
  } catch (error) {
    return {
      props: {
        status: 500,
      },
    };
  }
}
