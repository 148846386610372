import React from 'react';
import { Helmet } from 'react-helmet';

const MetaCategory = ({ serverData }) => {
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" defer={false}></meta>
        <title>{`${serverData?.categoryName} | 家速配，最快1小時送達`}</title>
        <h1>{`${serverData?.categoryName}`}</h1>
        <meta
          property="description"
          content={`最熱銷的家速配${serverData?.categoryName}推薦！提供多款${serverData?.categoryName}，方便買快速配。家速配是家樂福快速配送服務，可累積也可折抵家樂福會員紅利點數！預約下單可指定時間，宅配、到店取貨都方便！標示24h門市不打烊，半夜也能送到家。無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！`}
        ></meta>
        <meta
          property="og:title"
          content={`${serverData?.categoryName} | 家速配，最快1小時送達`}
        />
        <meta
          property="og:description"
          content={`最熱銷的家速配${serverData?.categoryName}推薦！提供多款${serverData?.categoryName}，方便買快速配。家速配是家樂福快速配送服務，可累積也可折抵家樂福會員紅利點數！預約下單可指定時間，宅配、到店取貨都方便！標示24h門市不打烊，半夜也能送到家。無論是煮飯、拜拜、工作、家庭等各種生活所需，家速配提供最快速到貨服務。下載家速配APP更方便！`}
        ></meta>
        <meta property="og:url" content={`${serverData?.crrentUrl}`} />
        {/* logo圖檔放在static裏面 部署的時候會搬到public */}
        <meta
          property="og:image"
          content={`https://${serverData?.host}/useimages/logo.png`}
        />
      </Helmet>
    </>
  );
};

export default MetaCategory;
