import React from 'react';
import { Helmet } from 'react-helmet';
import { SEO_DOMAIN } from 'utils/constants/seo.js';

const LdCategory = ({ serverData }) => {
  var schemaJSONLDProductsItemList = [];
  serverData?.result?.data?.rows?.forEach((p, idx) => {
    schemaJSONLDProductsItemList.push({
      '@type': 'ListItem',
      position: `${idx + 1}`,
      item: {
        '@type': 'Product',
        '@id': '',
        name: `${p?.productName}`,
        url: '',
        image: `${p?.image_path}`,
        // "description": `${p.shortDescription}`,
        brand: {
          '@type': 'Brand',
          name: `${p?.brand}`,
        },
        offers: {
          '@type': 'Offer',
          priceCurrency: 'TWD',
          price: `${p?.promo_price !== p?.price ? p?.promo_price : p?.price}`,
          availability: 'http://schema.org/InStock',
        },
      },
    });
  });
  var schemaJSONLDProducts = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDProductsItemList,
  };

  var schemaJSONLDCateMenuItemList = [];
  serverData?.cateMenu?.data?.rows?.forEach((cate, idx) => {
    cate?.sub?.forEach((subItem, idx) => {
      schemaJSONLDCateMenuItemList.push({
        '@type': 'ListItem',
        position: `${idx + 1}`,
        name: `${subItem?.name}`,
        url: `${SEO_DOMAIN}/category?cid=${subItem?.id}`,
      });
    });
  });
  var schemaJSONLDCateMenu = {
    '@type': 'ItemList',
    itemListElement: schemaJSONLDCateMenuItemList,
  };

  var schemaJSONLDGraphArray = [];
  schemaJSONLDGraphArray.push(schemaJSONLDProducts);
  schemaJSONLDGraphArray.push(schemaJSONLDCateMenu);

  var jsonldSchema = {
    '@context': 'https://schema.org/',
    '@graph': schemaJSONLDGraphArray,
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonldSchema)}
        </script>
      </Helmet>
    </>
  );
};

export default LdCategory;
