import React from 'react';
import styled from 'styled-components';

import IconNoData from 'images/no-data.svg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 263px;
    height: 300px;
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #8b8b8b;
`;

const Empty = (props) => {
  const { image = IconNoData, description = '' } = props;

  return (
    <Container>
      <img src={image} />
      <Text>{description}</Text>
    </Container>
  );
};

export default Empty;
